export const faqKeysGeneral = [
  "what-is-ecency",
  "what-is-difference",
  "what-is-hive",
  "why-choose-ecency",
  "how-ecency-works",
  "how-to-join",
  "can-change-username",
  "can-delete-account",
  "how-to-signin",
  "what-is-hivesigner",
  "what-is-keychain",
  "why-different-keys",
  "how-to-contribute",
  "how-referrals-work",
  "what-are-referrals",
  "what-information-profile",
  "what-ecency-wallet",
  "what-is-points",
  "what-gift-button-means",
  "where-tokens-come",
  "what-do-hive-tokens",
  "value-hive-hbd",
  "what-are-hive",
  "what-is-hive-power",
  "what-are-hd",
  "what-delegated-hp",
  "what-powering-up",
  "what-powering-down",
  "what-savings mean",
  "what-engine-tokens",
  "what-staking",
  "how-to-stake",
  "how-market-works",
  "how-to-swap",
  "what-are-rc",
  "possible-current-RC",
  "what-delegating-RC",
  "how-to-delegate",
  "what-voting-power",
  "how-many-vote",
  "stolen-hive-hbd",
  "hive-dollar-worth",
  "convert-hive-dollar",
  "what-dollar-amount",
  "how-voting-works",
  "what-reputation",
  "improve-reputation-score",
  "why-score-going-down",
  "what-can-post",
  "what-are-tags",
  "how-use-tags",
  "how-search-tags",
  "what-information-feed",
  "what-trending-feed",
  "how-sort-trending",
  "what-hot-feed",
  "what-new-feed",
  "what-friends-feed",
  "what-rising-feed",
  "how-sort-rising",
  "what-controversial-feed",
  "how-sort-controversial",
  "how-sort-feed",
  "how-change-design",
  "what-is-deck",
  "what-deck-types",
  "what-is-reblog",
  "how-to-reblog",
  "how-to-bookmark",
  "how-to-favorite",
  "what-notifications-get",
  "turn-off-notifications",
  "getting-post-notifications",
  "manage-notifications",
  "add-images-videos",
  "what-thumbnail-image",
  "videos-pictures-stored",
  "why-publish-gray",
  "how-schedule",
  "how-delete-scheduled",
  "how-set-beneficiaries",
  "what-links-show",
  "exclude-reblogs-feed",
  "how-to-sort-feed",
  "how-to-follow",
  "how-find-information-followers",
  "how-to-search",
  "how-to-search-advanced",
  "what-appears-search",
  "what-mean-promoted",
  "how-promotion-work",
  "who-are-curators",
  "criteria-boosting-post",
  "different-reward-choices",
  "what-my-blog",
  "how-find-comments",
  "how-often-comment",
  "can-reward-comments",
  "can-comment-boost",
  "what-information-available",
  "what-is-waves",
  "what-is-boost",
  "can-pin-comments",
  "what-are-snippets",
  "can-upload-video",
  "can-guest-post",
  "can-link-post",
  "source-label",
  "how-to-transfer",
  "what-my-rewards",
  "how-see-rewards",
  "when-claim-rewards",
  "what-curation-reward",
  "how-exchange-work",
  "how-boost-account",
  "how-dhf-work",
  "what-hive-witnesses",
  "how-many-witnesses",
  "sort-witnesses-page",
  "how-vote-witnesses",
  "how-vote-proposals",
  "what-spam-abuse",
  "find-friends",
  "how-create-community",
  "accounts-included-leaderboard",
  "find-communities",
  "what-communities",
  "how-post-community",
  "leave-community"
];

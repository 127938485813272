// Should be updated with more exchange names
export const EXCHANGE_ACCOUNTS = [
  "deepcrypto8",
  "gateiodeposit",
  "probithive",
  "mxchive",
  "huobi-pro",
  "coinexdeposit",
  "bittrex",
  "blocktrades",
  "changelly",
  "gopax-deposit",
  "hitbtc-exchange",
  "poloniex",
  "upbit-exchange",
  "onepagex",
  "bdhivesteem"
];
